/*eslint-disable*/
// import Logger from "../../Lib/Logger";
// import moment from "moment-timezone";
import moment from 'moment'

import momentJalali from "moment-jalaali";
import {AsYouType, parsePhoneNumberFromString} from "libphonenumber-js";
import {EventBus} from "@/event-bus";
import {mapGetters} from "vuex";

export default {
    data() {
        return {
            languages: [
                {name: 'انگلیسی', code: 'en'},
                {name: 'آفریقایی', code: 'af'},
                {name: 'آلبانی', code: 'sq'},
                {name: 'عربی', code: 'ar'},
                {name: 'بنگالی', code: 'bn'},
                {name: 'بلغاری', code: 'bg'},
                {name: 'کاتالین', code: 'ca'},
                {name: 'چینی', code: 'zh'},
                {name: 'کروات', code: 'hr'},
                {name: 'جمهوری چک', code: 'cs'},
                {name: 'دانمارکی', code: 'da'},
                {name: 'هلندی', code: 'nl'},
                {name: 'استونیایی', code: 'et'},
                {name: 'فارسی', code: 'fa'},
                {name: 'فنلاندی', code: 'fi'},
                {name: 'فرانسه', code: 'fr'},
                {name: 'آلمانی', code: 'de'},
                {name: 'یونانی', code: 'el'},
                {name: 'گجراتی', code: 'gu'},
                {name: 'عبری', code: 'he'},
                {name: 'هندی', code: 'hi'},
                {name: 'مجارستانی', code: 'hu'},
                {name: 'اندونزیایی', code: 'id'},
                {name: 'ایتالیایی', code: 'it'},
                {name: 'ژاپنی', code: 'ja'},
                {name: 'کانادایی', code: 'kn'},
                {name: 'کره‌ای', code: 'ko'},
                {name: 'لتونی', code: 'lv'},
                {name: 'لیتوانیایی', code: 'lt'},
                {name: 'مقدونی', code: 'mk'},
                {name: 'مالایایی', code: 'ms'},
                {name: 'مراتی', code: 'mr'},
                {name: 'نپالی', code: 'ne'},
                {name: 'نروژی', code: 'no'},
                {name: 'پنجابی', code: 'pa'},
                {name: 'لهستانی', code: 'pl'},
                {name: 'پرتقالی', code: 'pt'},
                {name: 'رومانیایی', code: 'ro'},
                {name: 'روسی', code: 'ru'},
                {name: 'اسلواکی', code: 'sk'},
                {name: 'اسلوونیایی', code: 'sl'},
                {name: 'سومالیایی', code: 'so'},
                {name: 'اسپانیایی', code: 'es'},
                {name: 'سوئدی', code: 'sv'},
                {name: 'سواحیلی', code: 'sw'},
                {name: 'تامیل', code: 'ta'},
                {name: 'تلوگو', code: 'te'},
                {name: 'تایلندی', code: 'th'},
                {name: 'فیلیپینی', code: 'tl'},
                {name: 'ترکی', code: 'tr'},
                {name: 'اکراینی', code: 'uk'},
                {name: 'اردو', code: 'ur'},
                {name: 'ویتنامی', code: 'vi'}
            ],
            phone: null,
            rules: {
                required: [
                    value => !!value || 'پر کردن این فیلد اجباری است',

                ],
                min: v => v.length >= 8 || 'Min 8 characters',
                emailRules: [
                    v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(v) || 'ایمیل را با ساختار مناسب وارد نمایید',
                    value => !!value || 'پر گردن این فیلد اجباری است',
                ],
                phoneRules:
                    [
                        v => !!v || 'شماره تماس را با ساختار مناسب وارد کنید',
                        input => {
                            if (input) {
                                let phone = input.replaceAll(' ', '');
                                const phoneNumber = parsePhoneNumberFromString(phone);
                                if (phoneNumber) {
                                    if (!phoneNumber.isValid()) {
                                        return 'شماره تماس با ساختار مناسب وارد کنید.';
                                    } else {
                                        return true;
                                    }
                                } else {
                                    return 'شماره تماس با ساختار مناسب وارد کنید.';
                                }
                            }
                        }
                    ]
            },
        }
    },
    computed: {
        ...mapGetters({
            'subscription': 'getSubscription',
        })
    },
    methods: {
        copyToClipboard(text) {
            navigator.clipboard.writeText(text)
                .then(() => {
                    // Emit an event or show a notification after successful copy
                    EventBus.$emit('notify', {text: 'کپی شد', color: 'green'});
                })
                .catch(err => {
                    // Handle the error if copying fails
                    console.error('Failed to copy text: ', err);
                    EventBus.$emit('notify', {text: 'خطا در کپی کردن', color: 'red'});
                });

        },
        logout() {
            EventBus.$emit('notify', {text: 'شما از حساب کاربری خود خارج شدید', color: 'black'})
            setTimeout(() => {
                localStorage.removeItem('token')
                window.location.replace('/login')
            }, 1000)
        },
        prepareQuery(query) {
            // if (query) {
            //     if (query.hasOwnProperty('start_at')) {
            //         console.log(query)
            //         let year = moment(query.start_at).format('YYYY')
            //         let month = moment(query.start_at).format('MM')
            //         let day = moment(query.start_at).format('d')
            //         // 2022-10-03
            //         query.start_at = this.persianToCalendars(1400, 12, 19)
            //         console.log(moment(query.start_at).utc().format('y/mm/dd'),'svdsdsvsdvsdv')
            //         // query.start_at = year + '/' + month + "/" + day;
            //         // query.start_at = moment(query.start_at).tz('CET').get()
            //     }
            // }
            return query;
        },
        requesting(type = null, name = null, paramsQuery = {}, body = {}, header = {}) {
            return this.$store.dispatch('generalRequest', {
                type: type,
                name: name,
                paramsQuery: paramsQuery,
                body: body,
                headers: header,
            })
        },

        // logger(data) {
        //     new Logger().log(data)
        // },
        filteringTime(date, format = "Y/M/D") {
            let options = {year: 'numeric', month: 'long', day: 'numeric'};
            return new Date(date).toLocaleDateString('fa-IR', options)
        },
        sentimentFiltering(val) {
            switch (val) {
                case 'neutral':
                    return 'طبیعی😐'
                case 'positive':
                    return 'مثبت😊'
                case 'negative':
                    return 'منفی☹️'
            }
        },
    },
    filters: {
        filterGender(val) {
            switch (val) {
                case 'male':
                    return 'مرد'
                case 'female':
                    return 'زن'
                case 'unknown':
                    return 'نامشخص'
            }
        },
        stateFilter(val) {
            switch (val) {
                case "waiting":
                    return 'درانتظار بررسی'
                case "rejected":
                    return 'ردشده'
                case "accepted":
                    return 'تاییدشده'
                default:
                    return val
            }
        },
        SubscriptionStateFilter(val) {
            switch (val) {
                case "valid":
                    return 'فعال'
                case "pending":
                    return 'درانتظار فعال سازی'
                case "expired":
                    return 'منقضی شده'
                default:
                    return val
            }
        },
        sentimentFilter(val) {
            switch (val) {
                case 'neutral':
                    return 'طبیعی'
                case 'positive':
                    return 'مثبت'
                case 'negative':
                    return 'منفی'
            }
        },
        dateFilter(val) {
            return momentJalali().utc(val, 'DD MMM YY HH:mm').format(' HH:mm  jYYYY/jMM/jDD');
        },
        dateFilterV2(val) {
            return momentJalali(val, 'YYYY-MM-DD HH:mm:ss').format(' HH:mm  jYYYY/jMM/jDD');
        },
        dateFilterWithOutTime(val) {
            return momentJalali(val, 'YYYY-MM-DD HH:mm:ss').format('jYYYY/jMM/jDD');
        },
        filteringTime(date, format = "Y/M/D") {
            let options = {year: 'numeric', month: 'numeric', day: 'numeric'};
            return new Date(date).toLocaleDateString('fa-IR', options)
        },
        filteringTimeWithTime(date, format = "Y/M/D") {
            let options = {
                year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric',
                minute: 'numeric',
            };
            return new Date(date).toLocaleDateString('fa-IR', options)
        }
    },
    watch: {
        phone() {
            // let p = this.phone;

            // if (this.phone.startsWith('00')) {
            //     this.phone = this.phone.substr(2);
            //     this.phone = '+' + this.phone;
            // }
            //
            // if (this.phone.startsWith('098')) {
            //     this.phone = this.phone.substr(1);
            //     this.phone = '+' + this.phone;
            // }
            //
            // if (this.phone.startsWith('09')) {
            //     this.phone = this.phone.substr(1);
            //     this.phone = '+98' + this.phone;
            // }

            // if (this.phone.startsWith(0)) {
            //   this.phone = this.phone.replace(0, '');
            // }
            //
            // let x = new AsYouType().input(this.selectedCountry.code + this.phone);
            // x = x.replace(this.selectedCountry.code, '');
            // this.phone = x;


            if (this.phone.startsWith('00')) {
                this.phone = this.phone.substr(2);
                this.phone = '+' + this.phone;
            }

            if (this.phone.startsWith('098')) {
                this.phone = this.phone.substr(1);
                this.phone = '+' + this.phone;
            }

            if (this.phone.startsWith('09')) {
                this.phone = this.phone.substr(1);
                this.phone = '+98' + this.phone;
            }

            this.phone = new AsYouType().input(this.phone);
        }
    },

}