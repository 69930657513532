<template>
  <div>
    <v-dialog width="500px" v-model="showMore">
      <v-card>
        <div class="dataBox d-flex flex-column ">
          <div class="headerBox">
            <div class="ml-2 ">
              <div class="authorName mt-1">
                {{ data.author }}
              </div>
              <div class="dateData  mt-1">
                {{ data.datetime | dateFilter }}
              </div>
            </div>
            <div>
              <img style="border-radius: 5px" :src="require(`../../assets/social/${data.social_media.key}.png`)"
                   width="40px" class="mt-1" alt="">
            </div>
          </div>
          <div class="contentDataWithoutLimit  mt-3">
            {{ data.content }}
          </div>
          <div class="sentimentArea mt-3 mb-3">
            <div class="sentimentTitle">
              تحلیل رفتار کاربر
            </div>
            <div class="sentimentData d-flex mt-1">
              <template v-if="data.sentiment && data.sentiment != ''">
                <div>
                  <img :src="require(`../../assets/emoji/${data.sentiment}.png`)" width="20px" class="mt-1" alt="">
                </div>
                <div class="mr-1">
                  {{ data.sentiment | sentimentFilter }}
                </div>
              </template>
              <div class="mr-1 mt-2" v-else>
                نامشخص
              </div>
            </div>
          </div>
          <v-spacer></v-spacer>
          <div class="detailLink mt-5 d-flex">
            <div class="flex-grow-1 pr-1">
              <a :href="data.link" target="_blank">
                <v-btn block elevation="0" class="font-weight-bold">
                  <v-icon>
                    mdi-reply
                  </v-icon>
                  پاسخ
                </v-btn>
              </a>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>


    <div class="dataBox d-flex flex-column " :id="`${data.id}_data_box`"
         :class="data.id == $route.query.id && 'activeBox'">
      <div class="headerBox">
        <div class="ml-2 ">
          <div class="authorName mt-1">
            {{ data.author }}
          </div>
          <div class="dateData mt-1">
            {{ data.created_at | createdAtFilter }}
          </div>
        </div>
        <div>
          <img style="border-radius: 5px" :src="require(`../../assets/social/${data.social_media.key}.png`)"
               width="40px" class="mt-1" alt="">
        </div>
      </div>
      <div class="contentData  mt-3" :id="`content_${id}`">
        {{ data.content }}
      </div>
      <div class="sentimentArea mt-3 mb-3">
        <div class="sentimentTitle">
          تحلیل احساس کاربر
        </div>
        <div class="sentimentData d-flex mt-1">
          <template v-if="data.sentiment && data.sentiment != ''">
            <div>
              <img :src="require(`../../assets/emoji/${data.sentiment}.png`)" width="20px" class="mt-1" alt="">
            </div>
            <div class="mr-1">
              {{ data.sentiment | sentimentFilter }}
            </div>
          </template>
          <div class="mr-1 mt-2" v-else>
            نامشخص
          </div>
        </div>
      </div>
      <div class="labelArea d-flex">
        <v-chip class="labelItem font-weight-bold" v-for="(label , i) in data.labels" :key="i" small color="primary">
          {{ label.title }} : {{ label.value | filterGender }}
        </v-chip>
      </div>
      <v-spacer></v-spacer>
      <div class="detailLink mt-5 d-flex">
        <v-row>
          <v-col cols="12" lg="6" md="12">
            <v-btn block elevation="0" @click="showMore = true" class="font-weight-bold" color="#f2f2f2">
              <v-icon size="20">
                mdi-account
              </v-icon>
              نمایش
            </v-btn>
          </v-col>
          <v-col cols="12" lg="6" md="12">
            <a :href="data.link" target="_blank">
              <v-btn block elevation="0" class="font-weight-bold">
                <v-icon>
                  mdi-reply
                </v-icon>
                پاسخ
              </v-btn>
            </a>
          </v-col>
        </v-row>

      </div>
    </div>
  </div>
</template>

<script>
import momentJalali from "moment-jalaali";
import moment from 'moment-timezone';

export default {
  props: {
    data: Object,
    keyword: String,
    id: Number,
    load_highlight: {
      default: true,
    }
  },
  data() {
    return {
      showMore: false,
    }
  },
  methods: {
    highlightKeyword(keyword) {
      // Select the div with the class 'contentData'
      const contentDiv = document.getElementById(`content_${this.id}`);

      if (contentDiv) {
        const content = contentDiv.innerHTML;


        // Create a new HTML content with the keyword highlighted
        const highlightedContent = content.replace(
            new RegExp(`(${keyword})`, 'gi'),
            '<span class="highlightDiv">$1</span>'
        );

        // Replace the old content with the new highlighted content
        contentDiv.innerHTML = highlightedContent;
      }
    }

  },
  mounted() {
    if (this.load_highlight) {
      this.highlightKeyword(this.keyword)
    }
  },
  filters: {
    createdAtFilter(val) {
      const tehranTime = moment(val).tz('Asia/Tehran');
      return momentJalali(tehranTime).format('HH:mm - jYYYY/jMM/jDD');
    }
  }
}
</script>

<style>
.highlightDiv {
  background-color: yellow;
  color: black;
  font-weight: bold;
}

a {
  text-decoration: none;
}

.authorName {
  height: 28px;
  overflow: hidden;
  text-align: left;
  font-family: bold !important;
}

.sentimentTitle {
  font-family: bold !important;
}

.dateData {
  text-align: left;
  font-size: 10px;
}

.sentimentArea {
  border-right: 5px solid #ff8601;
  padding: 15px;
}

.gender_label {
  background: #ff8601;
  font-size: 12px;
  color: white;
  font-family: bold !important;
  padding: 5px;
  border-radius: 10px;
}

.headerBox {
  display: flex;
  justify-content: end;
}

.dataBox {
  border: 1px solid #eeeeee;
  border-radius: 10px;
  padding: 20px;
  /*height: 280px;*/
  transition: all 0.3s;
}

.dataBox:hover {
  transition: all 0.3s;
  border-color: #ff8601;
}

.detailData {
  display: flex;
  margin-bottom: 10px;

}

.titleDetail {
}

.contentDetail {
  margin-right: 10px;
  font-weight: bold;
}

.contentData {
  height: 130px;
  line-height: 20pt;
  overflow: hidden;
  text-align: right;
}

.contentDataWithoutLimit {
  min-height: 130px;
  line-height: 20pt;
  overflow: hidden;
  text-align: right;
}

.activeBox {
  border-color: #ff8601;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.labelArea{
  min-height: 40px;
}
</style>